<template>
    <b-navbar class="pt-4 pb-4 mb-5 text-center" toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand>
            <router-link class="logo mx-auto position-absolute" to="/">
                <img v-b-toggle="collapse ? 'nav-collapse' : null" class="pb-1 pl-2 pr-2 bg-dark rounded-circle" id="logo" src="../assets/logo.png" alt="logo">
            </router-link>
        </b-navbar-brand>

        <b-navbar-toggle class="position-absolute" target="nav-collapse"></b-navbar-toggle>

        <b-collapse v-model="collapse" id="nav-collapse" class="bg-dark" is-nav>
            <b-navbar-nav>
                <b-nav-item class="col">
                    <router-link class="nav-link h4" to="/">ACCUEIL</router-link>
                </b-nav-item>
                <b-nav-item class="col">
                    <router-link class="nav-link h4" to="/histoire">HISTOIRE</router-link>
                </b-nav-item>
                <b-nav-item class="col">
                    <router-link class="nav-link h4" to="/medias">MEDIAS</router-link>
                </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item class="col">
                    <router-link class="nav-link h4" to="/shop">SHOP</router-link>
                </b-nav-item>
                <b-nav-item class="col">
                    <router-link class="nav-link h4" to="/sponsors">SPONSORS</router-link>
                </b-nav-item>
                <b-nav-item class="col">
                    <router-link class="nav-link h4" to="/contact">CONTACT</router-link>
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    export default {
        name: "NavBar",
        data() {
            return {
                collapse: false
            }
        }
    }
</script>

<style scoped>
    /*b-navbar-toggle*/
    .navbar-toggler {
        top: 0.5rem;
    }

    .navbar-nav {
        min-width: 40%;
    }

    .col {
        padding-left: 5px;
        padding-right: 5px;
    }

    .logo {
        width: 0;
        top: 0;
        left: 50%;
        margin-top: -0.5em;
    }

    #logo {
        transform: translateX(-50%);
        width: 130px;
    }

    #nav-collapse {
        transform: translate(0px, 5rem);
        margin-bottom: 2rem;
        margin-left: -2rem;
        margin-right: -1rem;
    }

    .nav-link .router-link-exact-active,
    .nav-link .router-link-exact-active:focus {
        color: white;
    }

    :focus {
        outline: 0;
    }

    @media (min-width: 992px) {

        #logo {
            width: 200px;
            padding-bottom: 0.5rem !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }

        #nav-collapse {
            transform: unset;
            margin-bottom: unset;
            margin-left: -1rem;
            margin-right: unset;
        }
    }
</style>