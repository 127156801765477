import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Histoire from './views/Histoire.vue'
import Medias from './views/Medias.vue'
import Shop from './views/Shop.vue'
import Sponsors from './views/Sponsors.vue'
import Contact from './views/Contact.vue'
import ErreurHTTP from './views/ErreurHTTP.vue'

Vue.use(Router);

export default new Router({
    // Le mode 'history' permet d'enlever les [hash] dans les liens
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/histoire',
            component: Histoire
        },
        {
            path: '/medias',
            component: Medias
        },
        {
            path: '/shop',
            component: Shop
        },
        {
            path: '/sponsors',
            component: Sponsors
        },
        {
            path: '/contact',
            component: Contact
        },
        {
            path: '*',
            component: ErreurHTTP
        }
    ]
})