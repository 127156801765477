import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import vClickOutside from 'v-click-outside'

// Vue.config.devtools = true;

Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.config.productionTip = false;

/* POUR LES ICONES DE FONTAWESOME */
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome'
import {
    faTshirt,
    faCircle,
    faPhoneAlt,
    faMapMarkerAlt,
    faSun,
    faSnowflake,
    faUser,
    faIdCardAlt,
    faEnvelope,
    faLock,
    faEye,
    faHome,
    faComments,
    faListUl,
    faBusinessTime,
    faInfoCircle,
    faShoppingCart,
    faTrashAlt,
    faTruck,
    faTag,
    faPlus,
    faMinus,
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
    faInstagram,
    faTwitter,
    faFacebookF,
    faTiktok,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faTshirt,
    faCircle,
    faPhoneAlt,
    faMapMarkerAlt,
    faSun,
    faSnowflake,
    faUser,
    faIdCardAlt,
    faEnvelope,
    faLock,
    faEye,
    faHome,
    faComments,
    faListUl,
    faBusinessTime,
    faInfoCircle,
    faShoppingCart,
    faTrashAlt,
    faTruck,
    faTag,
    faPlus,
    faMinus,
    faCheckCircle,
    faTimesCircle,
    // BRANDS
    faInstagram,
    faTwitter,
    faFacebookF,
    faTiktok,
    faYoutube
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');