<template>
    <div class="text-center">
        <div class="text-center">
            <div id="menu-btn" class="row position-absolute">
                <div v-b-tooltip.hover.top="sessionLogin" class="mr-3 ml-3">
                    <a v-on:click="showBox = showBox === (sessionConnected ? 3 : 1) ? 0 : (sessionConnected ? 3 : 1)"
                       class="cursor-pointer">
                        <font-awesome-icon icon="user" size="3x" class="color"/>
                    </a>
                </div>
                <div class="mr-3 ml-3">
                    <a v-on:click="showBox = showBox === 10 ? 0 : 10" class="cursor-pointer">
                        <font-awesome-icon icon="shopping-cart" size="3x" class="color"/>
                    </a>
                </div>
            </div>

            <div v-click-outside="clickOutsideMenu" v-if="showBox !== 0" id="menu-box"
                 class="position-absolute right-null border border-secondary rounded-lg">
                <div v-if="showBox === 1">
                    <form v-on:submit="connect" action="javascript:void(0)">
                        <div class="form-group">
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="user" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="login" type="text" placeholder="Nom d'utilisateur"
                                       class="w-100 border-0"/>
                            </div>
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="lock" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="password" :type="showPassword ? 'text' : 'password'"
                                       placeholder="Mot de passe" class="w-100 border-0"/>
                                <font-awesome-icon icon="eye" v-on:click="showPassword = !showPassword"
                                                   :style="{visibility: password.length > 0 ? 'visible' : 'hidden'}"
                                                   class="cursor-pointer mt-auto mb-auto"/>
                            </div>
                            <b-alert v-on:dismissed="sucMsgReg = false" :show="sucMsgReg" variant="success" dismissible
                                     fade>
                                Compte créé avec succès
                            </b-alert>
                            <b-alert v-on:dismissed="errMsgLog = false" :show="errMsgLog" variant="danger" dismissible
                                     fade>
                                <font-awesome-icon icon="user"/>
                                ou
                                <font-awesome-icon icon="lock"/>
                                incorrect
                            </b-alert>
                            <button type="submit" class="btn btn-monkeys d-block m-auto w-75">Se connecter</button>
                            <div class="mt-2">
                                <a href="javascript:void(0)" v-on:click="showBox = 2">S'enregistrer</a>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-if="showBox === 2">
                    <form v-on:submit="register" action="javascript:void(0)" role="form">
                        <div class="form-group">
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="user" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="registerLogin" type="text" placeholder="Nom d'utilisateur"
                                       class="w-100 border-0"/>
                            </div>
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="lock" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="registerPassword"
                                       :type="showRegisterPassword ? 'text' : 'password'" autocomplete="new-password"
                                       placeholder="Mot de passe" class="w-100 border-0"/>
                                <font-awesome-icon icon="eye" v-on:click="showRegisterPassword = !showRegisterPassword"
                                                   :style="{visibility: registerPassword.length > 0 ? 'visible' : 'hidden'}"
                                                   class="cursor-pointer mt-auto mb-auto"/>
                            </div>
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="envelope" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="registerEmail" type="email" placeholder="Adresse email"
                                       class="w-100 border-0"/>
                            </div>
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="id-card-alt" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="registerName" type="text" placeholder="Nom"
                                       class="w-100 border-0"/>
                            </div>
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="id-card-alt" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="registerVorname" type="text" placeholder="Prénom"
                                       class="w-100 border-0"/>
                            </div>
                            <div class="d-inline-flex align-items-center mb-2">
                                <font-awesome-icon icon="info-circle" size="lg" class="mr-2"
                                                   v-b-tooltip.hover.top="'Toutes vos informations personnelles sont sécurisées et restent strictement confientielles'"/>
                                <span class="font-italic">Confidentialité</span>
                            </div>
                            <b-alert v-on:dismissed="errMsgReg = false" :show="errMsgReg" variant="danger" dismissible
                                     fade>
                                <font-awesome-icon icon="user"/>
                                ou
                                <font-awesome-icon icon="envelope"/>
                                est déjà utilisé
                            </b-alert>
                            <button type="submit" class="btn btn-monkeys d-block m-auto w-75">S'enregistrer</button>
                            <div class="mt-2">
                                <a href="javascript:void(0)" v-on:click="showBox = 1">Retour</a>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-if="showBox === 3">
                    <div v-if="sessionAdmin > 0" class="m-4">
                        <button v-on:click="showBox = 9" class="btn btn-monkeys d-block m-auto w-100">
                            Administration
                        </button>
                    </div>
                    <div class="m-4">
                        <button v-on:click="showAccountInfos" class="btn btn-monkeys d-block m-auto w-100">
                            Modifier mon compte
                        </button>
                    </div>
                    <div class="m-4">
                        <button v-on:click="showAccountAddress" class="btn btn-monkeys d-block m-auto w-100">
                            Modifier mon adresse
                        </button>
                    </div>
                    <div class="m-4">
                        <b-button v-on:click="showHistoryOrders" v-b-modal.modal-order
                                  class="btn btn-monkeys d-block m-auto w-100">
                            Commandes
                        </b-button>
                    </div>
                    <div class="m-4">
                        <button v-on:click="disconnect" class="btn btn-monkeys d-block m-auto w-100">
                            Se déconnecter
                        </button>
                    </div>
                </div>

                <div v-if="showBox === 4">
                    <form v-on:submit="modAccount" action="javascript:void(0)">
                        <div class="form-group">
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="envelope" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="newEmail" type="email" placeholder="Nouvel email" autocomplete="no"
                                       class="w-100 border-0"/>
                            </div>
                            <div class="input d-flex m-4 p-2 rounded-lg">
                                <font-awesome-icon icon="lock" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="newPassword" :type="showNewPassword ? 'text' : 'password'"
                                       autocomplete="new-password"
                                       placeholder="Nouveau mot de passe" class="w-100 border-0"/>
                                <font-awesome-icon icon="eye" v-on:click="showNewPassword = !showNewPassword"
                                                   :style="{visibility: newPassword.length > 0 ? 'visible' : 'hidden'}"
                                                   class="cursor-pointer mt-auto mb-auto"/>
                            </div>
                            <b-alert v-on:dismissed="sucMsgAccPas = false" :show="sucMsgAccPas" variant="success"
                                     dismissible
                                     fade>
                                Mot de passe modifié avec succès
                            </b-alert>
                            <button type="submit" class="btn btn-monkeys d-block m-auto w-75">
                                Modifier mon compte
                            </button>
                            <div class="mt-2">
                                <a href="javascript:void(0)" v-on:click="showBox = 3">Retour</a>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-if="showBox === 5">
                    <div class="row m-4">
                        <div class="col input d-flex p-2 rounded-lg">
                            <font-awesome-icon icon="home" class="mt-auto mb-auto"/>
                            <span class="user-select-none">|</span>
                            <input v-model="NPA" type="number" placeholder="NPA" min="1000" max="9999"
                                   class="w-100 border-0" v-on:change="modAccountAddress(NPA, city, street)"/>
                        </div>
                        <div class="col-1"></div>
                        <div class="col-7 input d-flex p-2 rounded-lg">
                            <input v-model="city" type="text" placeholder="Ville" class="w-100 border-0"
                                   v-on:change="modAccountAddress(NPA, city, street)"/>
                        </div>
                    </div>
                    <div class="input d-flex m-4 p-2 rounded-lg">
                        <font-awesome-icon icon="map-marker-alt" class="mt-auto mb-auto"/>
                        <span class="user-select-none">|</span>
                        <input v-model="street" type="text" placeholder="Rue" class="w-100 border-0"
                               v-on:change="modAccountAddress(NPA, city, street)"/>
                    </div>
                    <b-alert v-on:dismissed="sucMsgAccAdd = false" :show="sucMsgAccAdd" variant="success"
                             dismissible
                             fade>
                        Adresse modifiée avec succès
                    </b-alert>
                    <div class="mt-2 mb-3">
                        <a href="javascript:void(0)" v-on:click="showBox = 3">Retour</a>
                    </div>
                </div>

                <div v-if="showBox === 9">
                    <div class="m-4">
                        <b-button v-on:click="taskSelectedPK = -1; showTasks(); showAccountAdminVornames();"
                                  v-b-modal.modal-tasks
                                  class="btn btn-monkeys d-block m-auto w-100">
                            Tâches
                        </b-button>
                    </div>
                    <div class="m-4">
                        <b-button v-on:click="showUnpaidOrders(); showUndeliveredOrders()" v-b-modal.modal-admin-order
                                  class="btn btn-monkeys d-block m-auto w-100">
                            Commandes
                        </b-button>
                    </div>
                    <div class="m-4">
                        <b-button v-on:click="$bvModal.show('modal-stocks')"
                                  class="btn btn-monkeys d-block m-auto w-100">
                            Stocks
                        </b-button>
                    </div>
                    <div class="m-4">
                        <b-button v-on:click=""
                                  class="btn btn-monkeys d-block m-auto w-100">
                            Utilisateurs
                        </b-button>
                    </div>
                    <div class="mb-2">
                        <a href="javascript:void(0)" v-on:click="showBox = 3">Retour</a>
                    </div>
                </div>

                <div v-if="showBox === 10" class="p-3">
                    <div v-if="shoppingCart.length > 0">
                        <h4>Panier</h4>
                        <div v-for="sc in shoppingCart" class="d-flex position-relative pt-2 pb-2 border-article">
                            <div v-on:click="delShoppingCart(sc.pk, sc.size, true)"
                                 class="trash cursor-pointer position-absolute">
                                <font-awesome-icon icon="trash-alt" class="mt-auto mb-auto"/>
                            </div>
                            <div class="w-25">
                                <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+sc.frontimage"
                                     style="width: 150px">
                            </div>
                            <div class="article">
                                <div class="text-left">
                                    <b>{{sc.name}}</b>
                                </div>
                                <div class="d-flex mt-3 align-items-center">
                                    <div class="d-flex ml-3">
                                        <span
                                            class="w-50px d-flex mr-4 border border-secondary rounded-lg align-items-center justify-content-center"
                                            :style="{visibility: sc.size !== null ? 'visible' : 'hidden'}">{{sc.size}}</span>
                                        <button v-on:click="delShoppingCart(sc.pk, sc.size, false)"
                                                class="btn btn-monkeys d-block">
                                            <font-awesome-icon icon="minus" class="mt-auto mb-auto"/>
                                        </button>
                                        <span
                                            class="w-40px d-flex ml-1 mr-1 border border-secondary rounded-lg align-items-center justify-content-center">{{sc.nb}}</span>
                                        <button v-on:click="addShoppingCart(sc.pk, sc.size)"
                                                class="btn btn-monkeys d-block">
                                            <font-awesome-icon icon="plus" class="mt-auto mb-auto"/>
                                        </button>
                                    </div>
                                    <b class="position-absolute right-null">{{sc.nb * sc.price}} CHF</b>
                                </div>
                            </div>
                        </div>
                        <span v-if="posteDelivery" class="d-flex justify-content-between p-2">
                            <font-awesome-icon icon="truck" class="mr-2 color align-self-center"/>
                            <b class="mr-auto">Frais de livraison </b>
                            <b>{{posteAmount}} CHF</b>
                        </span>
                        <span v-if="codeReduction > 0" class="d-flex justify-content-between p-2">
                            <font-awesome-icon icon="tag" class="mr-2 color align-self-center"/>
                            <b class="mr-auto">Vous économisez </b>
                            <b>-{{codeReduction}} CHF</b>
                        </span>
                        <span class="d-flex justify-content-between p-2">
                            <b>Total</b>
                            <b>{{totalShoppingCart()}} CHF</b>
                        </span>
                        <b-button v-b-modal.modal-confirm-order v-on:click="showAccountAddress(false)"
                                  class="btn btn-monkeys d-block w-100">
                            Passer en caisse
                        </b-button>
                    </div>
                    <div v-else>
                        Panier vide ! Ajoutez un article pour accéder au panier.
                    </div>
                </div>
            </div>

            <b-modal hide-footer id="modal-confirm-email" title="Email envoyé">
                <p>Un email vous a été envoyé à votre adresse spécifiée pour que vous la validiez.</p>
            </b-modal>

            <b-modal hide-footer id="modal-suc-ena-acc" title="Adresse email validée avec succès">
                <font-awesome-icon icon="check-circle" size="3x" class="d-block m-auto text-success"/>
            </b-modal>

            <b-modal hide-footer id="modal-err-ena-acc" title="Adresse email non validée, le lien n'est pas valide">
                <font-awesome-icon icon="times-circle" size="3x" class="d-block m-auto text-danger"/>
            </b-modal>

            <b-modal hide-footer id="modal-suc-mod-acc-email" title="Adresse email modifiée avec succès">
                <font-awesome-icon icon="check-circle" size="3x" class="d-block m-auto text-success"/>
            </b-modal>

            <b-modal hide-footer id="modal-err-mod-acc-email" title="Adresse email déjà utilisée ou lien invalide">
                <font-awesome-icon icon="times-circle" size="3x" class="d-block m-auto text-danger"/>
            </b-modal>

            <b-modal hide-footer id="modal-order" size="lg" title="Historique des commandes">
                <div v-if="historyOrder === null">
                    <h5 class="text-center">Chargement...</h5>
                </div>
                <div v-else-if="historyOrder.length === 0">
                    <h5 class="text-center">Vous n'avez aucune commande</h5>
                </div>
                <div v-for="ho in historyOrder" class="content-order position-relative border-order">
                    <div class="content-text-order mb-3">
                        <h5>Commande no. {{ho.pk}} du {{ho.date | moment}}</h5>
                        <b-button v-if="ho.paid < 1" v-on:click="pkDelOrder = ho.pk" v-b-modal.modal-sure-del-order
                                  class="btn btn-danger">
                            <font-awesome-icon icon="trash-alt" size="1x" class="d-block m-auto"/>
                        </b-button>
                    </div>
                    <div class="content-text-order mb-2">
                        <div>
                            <span>Statut de paiement : </span>
                            <span v-if="ho.paid > 0" class="text-success">payé</span>
                            <span v-else class="text-danger">non payé</span>
                        </div>
                        <div>
                            <span>Statut de livriason : </span>
                            <span v-if="ho.delivered > 0" class="text-success">livré</span>
                            <span v-else class="text-danger">non livré</span>
                        </div>
                        <div>
                            <span>Montant total : </span>
                            <span class="font-weight-bold">{{ho.total}} CHF</span>
                        </div>
                    </div>
                    <div v-for="product in ho.products"
                         class="d-flex position-relative pt-2 pb-2 border-article">
                        <img class="img-order img-fluid" v-bind:src="'data:image/jpeg;base64,'+product.frontimage">
                        <div class="w-100 m-auto">
                            <div class="content-info-order d-flex">
                                <div class="w-100">
                                    <b class="ml-3">{{product.nb}}x</b>
                                    <b class="ml-4">{{product.name}}</b>
                                </div>
                                <div class="d-flex justify-content-between w-100">
                                    <span class="size-order w-50px text-center"
                                          :style="{visibility: product.size !== null ? 'visible' : 'hidden'}">
                                        {{product.size}}
                                    </span>
                                    <span class="price-order w-92px text-center">{{product.price}} CHF</span>
                                    <b class="w-92px text-right">{{product.nb * product.price}} CHF</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="ho.poste > 0" class="d-flex position-relative border-article">
                        <font-awesome-icon icon="truck" class="ml-3 mr-2 color align-self-center"/>
                        <div class="w-100 m-auto">
                            <div class="content-info-order d-flex">
                                <div class="w-100">
                                    <b class="ml-3">Frais de livraison</b>
                                </div>
                                <div class="d-flex">
                                    <b class="w-92px text-right">{{ho.poste}} CHF</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="ho.reduction > 0" class="d-flex position-relative border-article">
                        <font-awesome-icon icon="tag" class="ml-3 mr-2 color align-self-center"/>
                        <div class="w-100 m-auto">
                            <div class="content-info-order d-flex">
                                <div class="w-100">
                                    <b class="ml-3">Code promo :</b>
                                    <b class="ml-4">{{ho.code}}</b>
                                </div>
                                <div class="d-flex">
                                    <b class="w-92px text-right">-{{ho.reduction}} CHF</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-sure-del-order" title="Êtes-vous sûr de vouloir supprimer cette commande ?">
                <div class="row">
                    <div class="col">
                        <button v-on:click="delOrder(pkDelOrder); $bvModal.hide('modal-sure-del-order')"
                                class="m-auto w-50 btn btn-success d-block">
                            Oui
                        </button>
                    </div>
                    <div class="col">
                        <button v-on:click="$bvModal.hide('modal-sure-del-order')"
                                class="m-auto w-50 btn btn-danger d-block">
                            Non
                        </button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-tasks" size="xl" title="Listes des tâches">
                <div class="row">
                    <div v-for="(listingTask, n) in listingTasksStates" class="col-4">
                        <h1 v-b-tooltip.hover.top="listingTask.title"
                            :class="'text-center p-1 bg-'+listingTask.color+' rounded-bg-top'">
                            <font-awesome-icon :icon="listingTask.icon" size="2x" class="d-block m-auto"/>
                        </h1>
                        <draggable :class="'list-group border border-'+listingTask.color" tag="ul"
                                   v-model="listingTasksCategories[n]" v-bind="dragOptions"
                                   @start="drag = true" @end="drag = false" @add="onMoveDraggable">
                            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                <li v-on:mousedown="taskSelectedPK = task.pk" v-on:click="taskSelectedPK = task.pk"
                                    v-on:dblclick="loadOneTask(true)" :class="{taskSelected:task.pk === taskSelectedPK}"
                                    class="pr-5 list-group-item cursor-pointer"
                                    v-for="task in listingTasksCategories[n]" :key="task.pk">
                                    {{task.title}}
                                    <div class="position-absolute row mr-2 circles right-null">
                                        <div v-for="admin in admins"
                                             v-if="task.accounts.find(a => a.vorname === admin.vorname) !== undefined"
                                             :class="'bg-' + admin.color + ' rounded-circle'"></div>
                                    </div>
                                </li>
                            </transition-group>
                        </draggable>
                    </div>
                    <h5 v-if="tasks === null" class="col text-center">Chargement...</h5>
                    <h5 v-else-if="tasks.length === 0" class="col text-center">Aucune tâche</h5>
                </div>
                <div class="mt-5 d-flex justify-content-around">
                    <div v-for="admin in admins" class="row circles">
                        <div :class="'bg-' + admin.color + ' rounded-circle'"></div>
                        <span class="col d-flex align-items-center font-weight-bold">{{admin.vorname}}</span>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-4">
                        <b-button v-b-modal.modal-add-task
                                  class="btn btn-monkeys d-block m-auto w-100">Ajouter
                        </b-button>
                    </div>
                    <div class="col-4">
                        <b-button :disabled="taskSelectedPK < 0" v-on:click="loadOneTask(true)"
                                  class="btn btn-monkeys d-block m-auto w-100">Modifier
                        </b-button>
                    </div>
                    <div class="col-4">
                        <b-button :disabled="taskSelectedPK < 0" v-b-modal.modal-sure-end-task
                                  class="btn btn-monkeys d-block m-auto w-100">Terminer
                        </b-button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-add-task" title="Ajouter une tâche">
                <form v-on:submit="addTask" action="javascript:void(0)" role="form">
                    <div class="form-group">
                        <div class="input d-flex m-4 p-2 rounded-lg">
                            <input v-model="newTaskTitle" type="text" placeholder="Titre" class="w-100 border-0"/>
                        </div>
                        <div class="input d-flex m-4 p-2 rounded-lg">
                            <textarea v-model="newTaskDescription" placeholder="Description"
                                      :rows="newTaskDescription.match(/\n/g) !== null ? newTaskDescription.match(/\n/g).length + 1 : 3"
                                      class="textarea-min-height w-100 border-0"></textarea>
                        </div>
                        <div class="input d-flex m-4 p-2 rounded-lg">
                            <select v-model="newTaskPKAccount" class="w-100 border-0">
                                <option v-for="account in accountAdminVornames" v-bind:value="account.pk">
                                    {{account.vorname}}
                                </option>
                            </select>
                        </div>
                        <b-alert v-on:dismissed="errMsgAddTask = false" :show="errMsgAddTask" class="m-4"
                                 variant="danger" dismissible fade>Remplis tout les champs stp
                        </b-alert>
                        <button type="submit" class="btn btn-monkeys d-block m-auto w-75">Ajouter la tâche</button>
                    </div>
                </form>
            </b-modal>

            <b-modal hide-footer id="modal-task" size="lg" title="Tâche">
                <div class="form-group">
                    <div class="input d-flex m-4 p-2 rounded-lg">
                        <input v-on:change="modTask(true)" v-model="taskTitle" type="text" placeholder="Titre"
                               class="w-100 border-0"/>
                    </div>
                    <div class="input d-flex m-4 p-2 rounded-lg">
                            <textarea v-on:change="modTask(true)" v-model="taskDescription" placeholder="Description"
                                      :rows="taskDescription.match(/\n/g) !== null ? taskDescription.match(/\n/g).length + 1 : 3"
                                      class="textarea-min-height w-100 border-0"></textarea>
                    </div>
                    <div class="input d-flex m-4 p-2 rounded-lg">
                        <select v-on:change="modTask(true)" v-model="taskState" class="w-100 border-0">
                            <option v-for="(list, n) in listingTasksStates" :value="n">{{list.title}}</option>
                        </select>
                    </div>
                    <div class="row m-4">
                        <div v-for="account in taskAccounts" class="col input d-flex m-1 p-2 rounded-lg">
                            <input v-model="account.vorname" disabled type="text" class="w-100 border-0"/>
                            <font-awesome-icon v-on:click="delAccountTask(account.pk)" icon="times-circle"
                                               class="mt-auto mb-auto text-danger cursor-pointer"/>
                        </div>
                    </div>
                    <hr/>
                    <div class="row m-4">
                        <div class="col-6 input d-flex m-auto p-2 rounded-lg">
                            <select v-model="taskAddPKAccount" class="w-100 border-0">
                                <option
                                    v-for="account in accountAdminVornames.filter(a => !taskAccounts.find(({pk}) => a.pk === pk))"
                                    v-bind:value="account.pk">{{account.vorname}}
                                </option>
                            </select>
                        </div>
                        <button v-on:click="addAccountTask()" class="col-4 m-auto btn btn-success d-block">Ajouter
                        </button>
                    </div>
                    <b-alert v-on:dismissed="errMsgModTask = false" :show="errMsgModTask" class="m-4" variant="danger"
                             dismissible fade>La tâche n'a pas pu être modifiée, complète toutes les informations
                    </b-alert>
                    <div class="row m-4">
                        <b-button v-b-modal.modal-sure-end-task class="btn btn-monkeys m-auto w-50">
                            Terminer la tâche
                        </b-button>
                        <b-button v-b-modal.modal-sure-del-task class="btn btn-danger">
                            <font-awesome-icon icon="trash-alt" class="mt-auto mb-auto"/>
                        </b-button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-admin-order" size="lg" title="Listes des commandes">
                <b-form-group>
                    <b-form-radio-group v-model="selected" buttons button-variant="outline-secondary"
                                        name="radios-btn-default" size="lg">
                        <b-form-radio value="1">Commandes non payées</b-form-radio>
                        <b-form-radio value="2">Commandes non livrées</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <div v-if="selected === '1'">
                    <div v-if="unpaidOrder === null">
                        <h5 class="text-center">Chargement...</h5>
                    </div>
                    <div v-else-if="unpaidOrder.length === 0">
                        <h5 class="text-center">Aucune commande n'a pas été payée</h5>
                    </div>
                </div>
                <div v-if="selected === '2'">
                    <div v-if="undeliveredOrder === null">
                        <h5 class="text-center">Chargement...</h5>
                    </div>
                    <div v-else-if="undeliveredOrder.length === 0">
                        <h5 class="text-center">Aucune commande n'a pas été livrée</h5>
                    </div>
                </div>
                <div v-for="li in (selected === '1' ? unpaidOrder : undeliveredOrder)"
                     class="content-order position-relative border-order">
                    <h5 class="mb-3"> Commande no. {{li.pk}} du {{li.date | moment}}</h5>
                    <div class="content-text-order mb-2">
                        <div v-if="selected === '1'">
                            <b-button v-on:click="pkOrderChange = li.pk" v-b-modal.modal-sure-order-paid
                                      class="btn btn-monkeys">
                                Commande payée
                            </b-button>
                            <b-button v-if="sessionAdmin > 1" v-on:click="pkOrderChange = li.pk"
                                      v-b-modal.modal-sure-order-send-email
                                      class="btn btn-monkeys ml-1">
                                <font-awesome-icon icon="envelope" class="mt-auto mb-auto"/>
                            </b-button>
                        </div>
                        <div v-if="selected === '2'">
                            <!-- obligé de faire avec button et pas b-button, ça fonctionne pas qd y en a 2-->
                            <button v-on:click="pkOrderChange = li.pk; $bvModal.show('modal-sure-order-delivered')"
                                    class="btn btn-monkeys">
                                Commande livrée
                            </button>
                        </div>
                        <div :id="'infoPersonal' + li.pk" class="mt-auto mb-auto font-weight-bold">
                            {{li.vorname}} {{li.name}}
                        </div>
                        <b-tooltip v-if="li.poste > 0" :target="'infoPersonal' + li.pk">
                            {{li.npa}} {{li.city}} <br> {{li.street}}
                        </b-tooltip>
                        <div class="mt-auto mb-auto">
                            <span>Montant total : </span>
                            <span class="font-weight-bold">{{li.total}} CHF</span>
                        </div>
                    </div>
                    <div v-for="product in li.products" class="d-flex position-relative pt-2 pb-2 border-article">
                        <img class="img-order img-fluid" v-bind:src="'data:image/jpeg;base64,'+product.frontimage">
                        <div class="w-100 m-auto">
                            <div class="content-info-order d-flex">
                                <div class="w-100">
                                    <b class="ml-3">{{product.nb}}x</b>
                                    <b class="ml-4">{{product.name}}</b>
                                </div>
                                <div class="d-flex justify-content-between w-100">
                                    <span class="size-order w-50px text-center"
                                          :style="{visibility: product.size !== null ? 'visible' : 'hidden'}">
                                        {{product.size}}
                                    </span>
                                    <span class="price-order w-92px text-center">{{product.price}} CHF</span>
                                    <b class="w-92px text-right">{{product.nb * product.price}} CHF</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="li.poste > 0" class="d-flex position-relative border-article">
                        <font-awesome-icon icon="truck" class="ml-3 mr-2 color align-self-center"/>
                        <div class="w-100 m-auto">
                            <div class="content-info-order d-flex">
                                <div class="w-100">
                                    <b class="ml-3">Frais de livraison</b>
                                </div>
                                <div class="d-flex">
                                    <b class="w-92px text-right">{{li.poste}} CHF</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="li.reduction > 0" class="d-flex position-relative border-article">
                        <font-awesome-icon icon="tag" class="ml-3 mr-2 color align-self-center"/>
                        <div class="w-100 m-auto">
                            <div class="content-info-order d-flex">
                                <div class="w-100">
                                    <b class="ml-3">Code promo :</b>
                                    <b class="ml-4">{{li.code}}</b>
                                </div>
                                <div class="d-flex">
                                    <b class="w-92px text-right">-{{li.reduction}} CHF</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-sure-end-task" title="Es-tu sûr que cette tâche est termineé ?">
                <div class="row">
                    <div class="col">
                        <button v-on:click="endTask(); $bvModal.hide('modal-sure-end-task')"
                                class="m-auto w-50 btn btn-success d-block">
                            Oui
                        </button>
                    </div>
                    <div class="col">
                        <button v-on:click="$bvModal.hide('modal-sure-end-task')"
                                class="m-auto w-50 btn btn-danger d-block">
                            Non
                        </button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-sure-del-task" title="Es-tu sûr de vouloir supprimer cette tâche ?">
                <div class="row">
                    <div class="col">
                        <button v-on:click="delTask(); $bvModal.hide('modal-sure-del-task')"
                                class="m-auto w-50 btn btn-success d-block">
                            Oui
                        </button>
                    </div>
                    <div class="col">
                        <button v-on:click="$bvModal.hide('modal-sure-del-task')"
                                class="m-auto w-50 btn btn-danger d-block">
                            Non
                        </button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-sure-order-paid" title="Es-tu sûr que la commande a bien été payée ?">
                <div class="row">
                    <div class="col">
                        <button v-on:click="modPaidOrder(pkOrderChange); $bvModal.hide('modal-sure-order-paid')"
                                class="m-auto w-50 btn btn-success d-block">
                            Oui
                        </button>
                    </div>
                    <div class="col">
                        <button v-on:click="$bvModal.hide('modal-sure-order-paid')"
                                class="m-auto w-50 btn btn-danger d-block">
                            Non
                        </button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-sure-order-delivered" title="Es-tu sûr que la commande a bien été livrée ?">
                <div class="row">
                    <div class="col">
                        <button
                            v-on:click="modDeliveredOrder(pkOrderChange); $bvModal.hide('modal-sure-order-delivered')"
                            class="m-auto w-50 btn btn-success d-block">
                            Oui
                        </button>
                    </div>
                    <div class="col">
                        <button v-on:click="$bvModal.hide('modal-sure-order-delivered')"
                                class="m-auto w-50 btn btn-danger d-block">
                            Non
                        </button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-sure-order-send-email" title="Envoyer un email pour cette commande ?">
                <input v-model="emailForSendEmail" type="text" placeholder="Email" class="w-100 border-0 m-3"/>
                <div class="row">
                    <div class="col">
                        <button
                            v-on:click="sendEmailOrderLater(pkOrderChange); $bvModal.hide('modal-sure-order-send-email')"
                            class="m-auto w-50 btn btn-success d-block">
                            Oui
                        </button>
                    </div>
                    <div class="col">
                        <button v-on:click="$bvModal.hide('modal-sure-order-send-email')"
                                class="m-auto w-50 btn btn-danger d-block">
                            Non
                        </button>
                    </div>
                </div>
            </b-modal>

            <b-modal hide-footer id="modal-stocks" size="xl" title="Gestion du stock">
                <div class="text-center">
                    <!--Stock pour les habits avec tailles-->
                    <table class="w-100">
                        <thead>
                        <tr>
                            <td></td>
                            <td v-for="size in listSizes">{{size.name}}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="clothe.sizes !== null && clothe.sizes[0].name.includes('X')"
                            v-for="clothe in clothes">
                            <td class="w-25 text-left">{{clothe.name}}</td>
                            <td v-for="size in listSizes" class="case-stock">
                                <div v-if="size.name === csize.name" v-for="csize in clothe.sizes">
                                    <input v-model="csize.stock" v-on:change="modStock(clothe.pk, csize.name)"
                                           class="w-75 input-stock text-center" type="number">
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <hr>
                    <!--Stock pour les habits sans tailles-->
                    <table class="w-100">
                        <tbody>
                        <tr v-if="clothe.sizes === null" v-for="clothe in clothes">
                            <td class="w-25 text-left">{{clothe.name}}</td>
                            <td class="case-stock">
                                <input v-model="clothe.stock" v-on:change="modStock(clothe.pk, null)"
                                       class="input-stock text-center" type="number">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <hr>
                    <!--Stock pour les habits avec couleurs-->
                    <table class="w-100">
                        <thead>
                        <tr>
                            <td></td>
                            <td v-for="color in listColors">{{color.name}}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="clothe.sizes !== null && clothe.sizes[0].name === 'Noir'" v-for="clothe in clothes">
                            <td class="w-25 text-left">{{clothe.name}}</td>
                            <td v-for="size in clothe.sizes" class="case-stock">
                                <input v-model="size.stock" v-on:change="modStock(clothe.pk, size.name)"
                                       class="w-75 input-stock text-center" type="number">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <b-alert v-on:dismissed="errMsgModStock = false" :show="errMsgModStock" class="m-4" variant="danger"
                             dismissible fade>La modification dans le stock n'a pas pu être faite, actualise la page et
                        réessai
                    </b-alert>
                </div>
            </b-modal>

            <!--            UTILISATEUR-->

            <b-modal hide-footer id="modal-confirm-order" title="Confirmation de commande">
                <div v-for="sc in shoppingCart" class="d-flex position-relative pt-2 pb-2 border-article-inverse">
                    <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+sc.frontimage"
                         style="width: 100px">
                    <div class="w-100">
                        <div class="text-left">
                            <b>{{sc.name}}</b>
                        </div>
                        <div class="d-flex mt-3">
                            <span class="w-50px m-auto border border-secondary rounded-lg text-center"
                                  :style="{visibility: sc.size !== null ? 'visible' : 'hidden'}">{{sc.size}}</span>
                            <span class="w-92px mr-auto border border-secondary rounded-lg text-center">
                                <b>{{sc.nb}}x</b> {{sc.price}} CHF
                            </span>
                            <b class="ml-auto">{{sc.nb * sc.price}} CHF</b>
                        </div>
                    </div>
                </div>
                <div v-if="posteDelivery" class="d-flex justify-content-between p-2">
                    <font-awesome-icon icon="truck" class="mr-2 color align-self-center"/>
                    <b class="mr-auto">Frais de livraison </b>
                    <b>{{posteAmount}} CHF</b>
                </div>
                <div v-if="codeReduction > 0" class="d-flex justify-content-between p-2">
                    <font-awesome-icon icon="tag" class="mr-2 color align-self-center"/>
                    <b class="mr-auto">Vous économisez </b>
                    <b>-{{codeReduction}} CHF</b>
                </div>
                <div class="d-flex justify-content-between mb-3 p-2 border-article-inverse">
                    <b>Total</b>
                    <b>{{totalShoppingCart()}} CHF</b>
                </div>
                <div class="d-flex m-3">
                    <div class="col-7 input d-flex p-2 rounded-lg">
                        <input v-on:change="newOrderCode = newOrderCode.toUpperCase().trim()" v-model="newOrderCode"
                               type="text" placeholder="Code promo" class="w-100 border-0"/>
                    </div>
                    <button v-on:click="verifCouponCode()" class="col-4 m-auto btn btn-success d-block">Appliquer
                    </button>
                </div>
                <div v-if="orderCode !== ''" class="d-flex m-3">
                    <div class="d-flex col-7 p-1 pl-2 pr-2 align-items-center bg-warning rounded-lg">
                        <font-awesome-icon icon="tag" class="mr-2 color"/>
                        <span>{{orderCode}}</span>
                        <font-awesome-icon v-on:click="orderCode = ''; codeAmount = 0" icon="times-circle"
                                           class="ml-auto text-danger cursor-pointer"/>
                    </div>
                </div>
                <b-alert v-on:dismissed="errMsgCode = false" :show="errMsgCode" variant="danger" dismissible fade>
                    Entrez un code de réduction valide
                </b-alert>
                <div>
                    <label for="posteFalse" class="rounded-bg shadow mb-3 p-2 w-100 poste cursor-pointer"
                           :class="!posteDelivery ? 'posteSelected' : ''">
                        <input v-model="posteDelivery" type="radio" :value="false" id="posteFalse" class="d-none">
                        <span class="posteRadio"/>
                        <span class="ml-4">Livraison aux cours/sessions de Parkour ou main à main</span>
                    </label>
                    <label for="posteTrue" class="rounded-bg shadow mb-3 p-2 w-100 poste cursor-pointer"
                           :class="posteDelivery ? 'posteSelected' : ''">
                        <input v-model="posteDelivery" type="radio" :value="true" id="posteTrue" class="d-none">
                        <span class="posteRadio"/>
                        <span class="ml-4">Livraison à votre domicile (Suisse) par poste pour <b>{{posteAmount}} CHF</b></span>
                        <div v-if="posteDelivery">
                            <div class="row m-0 mt-4 mb-4">
                                <div class="col input d-flex p-2 rounded-lg">
                                    <font-awesome-icon icon="home" class="mt-auto mb-auto"/>
                                    <span class="user-select-none">|</span>
                                    <input v-model="NPA" type="number" placeholder="NPA" min="1000" max="9999"
                                           class="w-100 border-0" v-on:change="modAccountAddress(NPA, city, street)"/>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-7 input d-flex p-2 rounded-lg">
                                    <input v-model="city" type="text" placeholder="Ville" class="w-100 border-0"
                                           v-on:change="modAccountAddress(NPA, city, street)"/>
                                </div>
                            </div>
                            <div class="input d-flex mt-4 mb-4 p-2 rounded-lg">
                                <font-awesome-icon icon="map-marker-alt" class="mt-auto mb-auto"/>
                                <span class="user-select-none">|</span>
                                <input v-model="street" type="text" placeholder="Rue" class="w-100 border-0"
                                       v-on:change="modAccountAddress(NPA, city, street)"/>
                            </div>
                            <b-alert v-on:dismissed="sucMsgAccAdd = false" :show="sucMsgAccAdd" variant="success"
                                     dismissible fade>
                                Adresse modifiée avec succès
                            </b-alert>
                            <b-alert v-on:dismissed="errMsgAccAdd = false" :show="errMsgAccAdd" variant="danger"
                                     dismissible fade>
                                Adresse manquante, veuillez en ajouter une !
                            </b-alert>
                        </div>
                    </label>
                </div>
                <div class="rounded-bg shadow mb-3 p-2">
                    <div class="d-flex">
                        <img class="img-fluid m-auto w-50 p-3" src="../assets/shop/twint.png"/>
                        <img class="img-fluid m-auto w-50 p-3" src="../assets/shop/virement_bancaire.png"/>
                    </div>
                    <p class="text-justify font-italic m-2">
                        Les informations pour le paiement se trouvent sur l'email envoyé après la validation de la
                        commande.
                    </p>
                </div>
                <button v-on:click="sessionConnected ? addOrder() : scrollTop(1)"
                        class="btn btn-monkeys d-block w-100">Valider la commande
                </button>
            </b-modal>

            <b-modal hide-footer id="modal-thanks-order" title="Merci pour votre commande">
                <p>La commande est enregistrée et un email vous a été envoyé pour que vous puissiez régler le montant
                    par Twint ou un virement bancaire</p>
                <p>Veuillez régler le montant au plus vite pour nous permettre de vous faire parvenir vos produits
                    rapidement.</p>
            </b-modal>
        </div>

        <div class="d-flex flex-wrap justify-content-center pt-5">
            <div v-for="(clothe, index) in clothes" v-on:click="hover = index" v-on:mouseover="hover = index"
                 v-on:mouseleave="hover = -1" class="products position-relative float-left p-1">
                <div v-b-modal.modal-product v-on:click="clotheSelected = clothe; indexSelected = index; loadImages()"
                     class="rounded-bg shadow m-3"> <!-- ICI LE CLIQUE -->
                    <div class="position-relative d-inline-flex pt-4 pb-3">
                        <div
                            v-if="clothe.stock === '0' || (clothe.sizes !== null ? clothe.sizes.filter(s => s.stock === '0').length === clothe.sizes.length : null)"
                            class="position-absolute d-inline-flex">
                            <img class="img-fluid" src="../assets/shop/sold_out.png">
                        </div>
                        <div v-if="clothe.backimage === ''" class="d-inline-flex">
                            <img class="img-fluid img-clothe" v-bind:src="'data:image/jpeg;base64,'+clothe.frontimage">
                        </div>
                        <div v-else
                             v-on:click="hoverimg !== index ? hoverimg = index : hoverimg = -1; hoverblock = true"
                             v-on:mouseover="!hoverblock ? hoverimg = index : null"
                             v-on:mouseleave="hoverimg = -1; hoverblock = false" class="d-inline-flex">
                            <transition name="fade">
                                <img class="img-fluid img-clothe"
                                     v-bind:src="'data:image/jpeg;base64,'+clothe.frontimage">
                            </transition>
                            <transition name="fade">
                                <img v-if="hoverimg === index" class="position-absolute img-fluid bg-white"
                                     v-bind:src="'data:image/jpeg;base64,'+clothe.backimage">
                            </transition>
                        </div>
                    </div>
                    <div class="info pb-3">
                        <div class="font-weight-bold pt-2 pb-3">{{clothe.name.toUpperCase()}}</div>
                        <div class="price">{{clothe.price}},00 CHF</div>
                    </div>
                </div>
            </div>
            <div v-if="clothes.length === 0" class="d-content m-auto">
                <h5>
                    <font-awesome-icon icon="tshirt" size="2x" class="mb-3"/>
                    <br>Chargement...
                </h5>
            </div>
        </div>

        <b-modal hide-footer id="modal-product" size="xl" :title="clotheSelected !== null ? clotheSelected.name : ''">
            <div v-if="clotheSelected !== null" class="row">
                <div class="col d-flex flex-wrap justify-content-center">
                    <gallery :images="clotheImages" :index="indexImage" @close="indexImage = null"></gallery>
                    <img class="clotheImage m-1" v-for="(imageUrl, imageIndex) in clotheImages"
                         :key="imageIndex" @click="indexImage = imageIndex"
                         :src="imageUrl" style="width: 126px; height: 94px">
                </div>
                <div class="col-5 info-product">
                    <span class="p-3" v-html="clotheSelected.description">{{clotheSelected.description}}</span>
                    <div class="w-100 text-center align-self-end">
                        <div class="p-2 h4 font-weight-bold">{{clotheSelected.price}},00 CHF</div>
                        <div class="bottom-null cursor-pointer p-2">
                            <div v-if="clotheSelected.sizes !== null" v-on:mouseover="hoversize = indexSelected"
                                 v-on:mouseleave="hoversize = -1" class="position-relative w-50 float-left">
                                <!--Never see, it's for good placement of sizes-->
                                <div class="border border-dark bg-white rounded-bg">
                                    {{clotheSelected.sizes[0].name.length >
                                3 ? 'COULEUR' : 'TAILLE'}}
                                </div>
                                <div class="bottom-null position-absolute w-100 border border-dark bg-white rounded-bg">
                                    <div
                                        v-if="(hoversize === -1) && (!sizeSelected.includes(clotheSelected.name + indexSelected))"
                                        class="size">
                                        <span>{{clotheSelected.sizes[0].name.length > 3 ? 'COULEUR' : 'TAILLE'}}</span>
                                    </div>
                                    <div v-for="size in clotheSelected.sizes"
                                         v-if="(hoversize === indexSelected) || (sizeSelected === clotheSelected.name + indexSelected + size.name)"
                                         v-on:click="size.stock > 0 ? sizeSelected = clotheSelected.name + indexSelected + size.name : null; size.stock > 0 ?  hoversize = hoversize === indexSelected ? -1 : indexSelected : null;"
                                         :disabled="size.stock < 1" class="size rounded-bg">
                                        <label class="w-100">
                                            <span>{{size.name}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-bind:class="{'btn-bg-green': sizeSelected.includes(clotheSelected.name + indexSelected) || clotheSelected.sizes === null}"
                                v-on:click="addShoppingCart(clotheSelected.pk, sizeSelected.includes(clotheSelected.name + indexSelected) > 0 ? sizeSelected.substring((clotheSelected.name + indexSelected).length) : null); $bvModal.hide('modal-product'); scrollTop(10)"
                                class="border border-dark btn-bg-dark rounded-bg text-white">AJOUTER
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import draggable from 'vuedraggable'
import VueGallery from 'vue-gallery';

export default {
    name: "Shop",
    data() {
        return {
            /*API*/
            url: (process.env.NODE_ENV === 'production' ? 'api/main.php' : 'main.php'),
            config: {headers: {'Content-Type': 'multipart/form-data'}},
            /*SESSION*/
            sessionLogin: '',
            sessionAdmin: 0,
            sessionConnected: false,
            /*Menu*/
            showBox: 0,
            disableClickOutside: false,
            /*Hover Shop*/
            clotheSelected: null,
            indexSelected: -1,
            hover: -1,
            hoverimg: -1,
            hoverblock: false,
            hoversize: -1,
            sizeSelected: '',
            /*Clothes*/
            clothes: [],
            clotheImages: [],
            images: [],
            indexImage: -1,
            /*Login*/
            login: '',
            password: '',
            showPassword: false,
            errMsgLog: false,
            /*Register*/
            registerLogin: '',
            registerPassword: '',
            registerEmail: '',
            registerName: '',
            registerVorname: '',
            showRegisterPassword: false,
            sucMsgReg: false,
            errMsgReg: false,
            /*Account*/
            newEmail: '',
            newPassword: '',
            showNewPassword: false,
            sucMsgAccPas: false,
            NPA: '',
            city: '',
            street: '',
            sucMsgAccAdd: false,
            errMsgAccAdd: false,
            accountAdminVornames: [],
            /*Task*/
            drag: false,
            listingTasksStates: [
                {title: 'À faire', color: 'info', icon: 'list-ul'},
                {title: 'En cours', color: 'success', icon: 'business-time'},
                {title: 'À discuter', color: 'warning', icon: 'comments'}
            ],
            admins: [
                {vorname: 'Brian', color: 'red'},
                {vorname: 'Baptiste', color: 'green'},
                {vorname: 'Kylian', color: 'darkred'},
                {vorname: 'Mike', color: 'blue'}
            ],
            tasks: [],
            listingTasksCategories: [],
            taskSelectedPK: -1,
            newTaskTitle: '',
            newTaskDescription: '',
            newTaskState: 0,
            newTaskPKAccount: 0,
            errMsgAddTask: false,
            taskTitle: '',
            taskDescription: '\n',
            taskState: 0,
            taskAccounts: [],
            taskAddPKAccount: 0,
            errMsgModTask: false,
            /*ShoppingCart*/
            shoppingCart: [],
            /*Order*/
            newOrderCode: '',
            orderCode: '',
            codePermanent: false,
            codeAmount: 0,
            codeReduction: 0,
            posteAmount: 7,
            posteDelivery: false,
            errMsgCode: false,
            unpaidOrder: null,
            undeliveredOrder: null,
            historyOrder: null,
            products: [],
            /*Stock*/
            listSizes: [
                {name: 'XXL'},
                {name: 'XL'},
                {name: 'L'},
                {name: 'M'},
                {name: 'S'},
                {name: 'XS'}
            ],
            listColors: [
                {name: 'Noir'},
                {name: 'Violet'},
                {name: 'Vert'},
                {name: 'Bleu'},
                {name: 'Rouge'}
            ],
            errMsgModStock: false,
            stock: null,
            /*Radio BTN*/
            selected: '1',
            emailForSendEmail: ''
        }
    },
    components: {
        draggable,
        'gallery': VueGallery
    },
    created() {
        this.getClothes();
        this.isConnected();
        if (location.search.indexOf('email=') === -1) {
            if (location.search.includes('?login=')) {
                let login = location.search.slice(location.search.indexOf('login=') + 6, location.search.indexOf('&id='));
                let id = location.search.slice(location.search.indexOf('id=') + 3);
                this.enableAccount(login, id);
            }
        } else {
            if (location.search.includes('?login=')) {
                let login = location.search.slice(location.search.indexOf('login=') + 6, location.search.indexOf('&email='));
                let email = location.search.slice(location.search.indexOf('email=') + 6, location.search.indexOf('&id='));
                let id = location.search.slice(location.search.indexOf('id=') + 3);
                this.modAccountEmail(login, email, id);
            }
        }
        history.replaceState(null, null, location.origin + location.pathname);
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD.MM.YYYY');
        }
    },
    methods: {
        getClothes() {
            let formData = new FormData();
            formData.append('action', 'showClothes');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                this.clothes = response.data;
                this.initializeShoppingCart();
            }).catch((error) => {
                console.error('Error get clothes: ' + error);
            })
        },
        modStock(pk_clothe, size) {
            let formData = new FormData();
            formData.append('action', 'modStock');
            formData.append('pk', pk_clothe);
            formData.append('size', size);
            formData.append('stock', this.surchStockClothe(pk_clothe, size));
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.errMsgModStock = false;
                } else {
                    this.errMsgModStock = true;
                }
            }).catch((error) => {
                console.error('Error mod stock: ' + error);
                this.disconnect();
            })
        },
        surchStockClothe(pk_clothe, size) {
            let stock;
            if (size !== null) {
                stock = this.clothes.find(c => c.pk === pk_clothe).sizes.find(s => s.name === size).stock;
            } else {
                stock = this.clothes.find(c => c.pk === pk_clothe).stock;
            }
            if (stock === '')
                stock = 0;
            return stock;
        },
        isConnected() {
            let formData = new FormData();
            formData.append('action', 'isConnected');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.sessionLogin = response.data[0].login;
                    this.sessionAdmin = response.data[0].admin;
                    this.sessionConnected = true;
                }
            }).catch((error) => {
                console.error('Error is connected: ' + error);
            })
        },
        connect() {
            let formData = new FormData();
            formData.append('action', 'connect');
            formData.append('login', this.login);
            formData.append('password', this.password);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.sessionLogin = response.data[0].login;
                    this.sessionAdmin = response.data[0].admin;
                    this.sessionConnected = true;
                    this.showBox = 3;
                    this.login = '';
                    this.password = '';
                    this.errMsgLog = false;
                } else {
                    this.errMsgLog = true;
                }
                this.sucMsgReg = false;
            }).catch((error) => {
                console.error('Error connect: ' + error);
            })
        },
        disconnect() {
            let formData = new FormData();
            formData.append('action', 'disconnect');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then(() => {
                this.sessionLogin = '';
                this.sessionConnected = false;
                this.showBox = 1;
                // Cache toutes les popup qui pourrait rester ouverte
                this.$bvModal.hide('modal-order');
                this.$bvModal.hide('modal-confirm-order');
                this.$bvModal.hide('modal-tasks');
                this.$bvModal.hide('modal-add-task');
                this.$bvModal.hide('modal-admin-order');
            }).catch((error) => {
                console.error('Error disconnect: ' + error);
            })
        },
        register() {
            let formData = new FormData();
            formData.append('action', 'addAccount');
            formData.append('login', this.registerLogin);
            formData.append('password', this.registerPassword);
            formData.append('name', this.registerName);
            formData.append('vorname', this.registerVorname);
            formData.append('email', this.registerEmail);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.login !== null) {
                    this.sendEmailConfirmAccount(response.data.login, this.registerEmail);
                    this.showBox = 1;
                    this.sucMsgReg = true;
                    this.errMsgReg = false;
                    this.registerLogin = '';
                    this.registerPassword = '';
                    this.registerName = '';
                    this.registerVorname = '';
                    this.registerEmail = '';
                } else {
                    this.sucMsgReg = false;
                    this.errMsgReg = true;
                }
            }).catch((error) => {
                console.error('Error register: ' + error);
            })
        },
        sendEmailConfirmAccount(login, email) {
            let formData = new FormData();
            formData.append('action', 'sendEmailConfirmAccount');
            formData.append('login', login);
            formData.append('email', email);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then(() => {
                this.$bvModal.show('modal-confirm-email');
                this.showBox = 0;
            }).catch((error) => {
                console.error('Error send email confirm account: ' + error);
            })
        },
        enableAccount(login, id) {
            let formData = new FormData();
            formData.append('action', 'enableAccount');
            formData.append('login', login);
            formData.append('id', id);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.$bvModal.show('modal-suc-ena-acc');
                } else {
                    this.$bvModal.show('modal-err-ena-acc');
                }
            }).catch((error) => {
                console.error('Error enable account: ' + error);
            });
        },
        disableAccount(pk) {
            let formData = new FormData();
            formData.append('action', 'disableAccount');
            formData.append('pk', pk);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    //nice
                }
            }).catch((error) => {
                console.error('Error disable account: ' + error);
            });
        },
        showAccountInfos() {
            let formData = new FormData();
            formData.append('action', 'showAccountInfos');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                this.showBox = 4;
                this.sessionEmail = response.data.email;
                this.newEmail = this.sessionEmail;
                this.newPassword = '';
            }).catch((error) => {
                console.error('Error show account info: ' + error);
                this.disconnect();
            })
        },
        showAccountAddress(changeShowBox) {
            let formData = new FormData();
            formData.append('action', 'showAccountAddress');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    if (changeShowBox)
                        this.showBox = 5;
                    this.NPA = response.data.npa > 0 ? response.data.npa : '';
                    this.city = response.data.city !== 'null' ? response.data.city : '';
                    this.street = response.data.street !== 'null' ? response.data.street : '';
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error show account address: ' + error);
                this.disconnect();
            })
        },
        showAccountAdminVornames() {
            let formData = new FormData();
            formData.append('action', 'showAccountAdminVornames');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.accountAdminVornames = response.data;
                }
            }).catch((error) => {
                console.error('Error show account admin vornames: ' + error);
                this.disconnect();
            })
        },
        showAccounts() {
            let formData = new FormData();
            formData.append('action', 'showAccounts');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.accounts = response.data;
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error show accounts: ' + error);
                this.disconnect();
            })
        },
        modAccount() {
            if (this.newEmail !== this.sessionEmail) {
                this.sendEmailConfirmEmail(this.newEmail);
            }
            if (this.newPassword.trim() !== null && this.newPassword.trim() !== '') {
                this.modAccountPassword();
            }
        },
        modAccountEmail(login, newEmail, id) {
            let formData = new FormData();
            formData.append('action', 'modAccountEmail');
            formData.append('login', login);
            formData.append('email', newEmail);
            formData.append('id', id);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.$bvModal.show('modal-suc-mod-acc-email');
                } else {
                    this.$bvModal.show('modal-err-mod-acc-email');
                }
            }).catch((error) => {
                console.error('Error mod account email: ' + error);
            });
        },
        sendEmailConfirmEmail(newemail) {
            let formData = new FormData();
            formData.append('action', 'sendEmailConfirmEmail');
            formData.append('newemail', newemail);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.$bvModal.show('modal-confirm-email');
                    this.showBox = 0;
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error send email confirm email: ' + error);
                this.disconnect();
            })
        },
        modAccountPassword() {
            let formData = new FormData();
            formData.append('action', 'modAccountPassword');
            formData.append('password', this.newPassword);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.showAccountInfos();
                    this.sucMsgAccPas = true;
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error mod account password: ' + error);
                this.disconnect();
            })
        },
        modAccountAddress(npa, city, street) {
            let formData = new FormData();
            formData.append('action', 'modAccountAddress');
            formData.append('npa', npa);
            formData.append('city', city);
            formData.append('street', street);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.showAccountAddress();
                    this.sucMsgAccAdd = true;
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error mod account address: ' + error);
            });
        },
        showHistoryOrders() {
            let formData = new FormData();
            formData.append('action', 'showHistoryOrders');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.historyOrder = response.data;
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error show history orders: ' + error);
                this.disconnect();
            })
        },
        delOrder(pk) {
            let formData = new FormData();
            formData.append('action', 'delOrder');
            formData.append('pk', pk);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.showHistoryOrders();
                }
            }).catch((error) => {
                console.error('Error del order: ' + error);
                this.disconnect();
            })
        },
        showTasks() {
            let formData = new FormData();
            formData.append('action', 'showTasks');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.tasks = response.data;
                    this.listingTasksCategories[0] = this.tasks.filter(t => t.state === '0');
                    this.listingTasksCategories[1] = this.tasks.filter(t => t.state === '1');
                    this.listingTasksCategories[2] = this.tasks.filter(t => t.state === '2');
                    this.loadOneTask(true);
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error show tasks: ' + error);
                this.disconnect();
            })
        },
        loadOneTask(showModal) {
            if (this.taskSelectedPK > 0) {
                let task = this.tasks.find(t => t.pk === this.taskSelectedPK);
                this.taskTitle = task.title;
                this.taskDescription = task.description;
                this.taskState = task.state;
                this.taskAccounts = task.accounts;
                this.errMsgModTask = false;
                if (showModal)
                    this.$bvModal.show('modal-task');
            } else {
                this.$bvModal.hide('modal-task');
            }
        },
        addTask() {
            let formData = new FormData();
            formData.append('action', 'addTask');
            formData.append('title', this.newTaskTitle);
            formData.append('description', this.newTaskDescription);
            formData.append('fk_account', this.newTaskPKAccount);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                this.taskSelectedPK = -1;
                this.showTasks();
                if (response.data.ok) {
                    this.$bvModal.hide('modal-add-task');
                    this.newTaskTitle = '';
                    this.newTaskDescription = '';
                    this.newTaskState = 0;
                    this.newTaskPKAccount = 0;
                    this.errMsgAddTask = false;
                } else {
                    this.errMsgAddTask = true;
                }
            }).catch((error) => {
                console.error('Error add task: ' + error);
                this.disconnect();
            })
        },
        modTask(showModal) {
            let formData = new FormData();
            formData.append('action', 'modTask');
            formData.append('pk', this.taskSelectedPK);
            formData.append('title', this.taskTitle);
            formData.append('description', this.taskDescription);
            formData.append('state', this.taskState);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    if (showModal)
                        this.showTasks();
                    this.errMsgModTask = false;
                } else {
                    this.errMsgModTask = true;
                }
            }).catch((error) => {
                console.error('Error mod task: ' + error);
                this.disconnect();
            })
        },
        onMoveDraggable(event) {
            this.taskSelectedPK = event.item._underlying_vm_.pk;
            this.loadOneTask(false);
            let state = "-1";
            let className = event.to.parentElement.className;
            if (className.indexOf("info") > 0) {
                state = "0";
            } else if (className.indexOf("success") > 0) {
                state = "1";
            } else if (className.indexOf("warning") > 0) {
                state = "2";
            }
            this.taskState = state;
            this.modTask(false);
        },
        addAccountTask() {
            let formData = new FormData();
            formData.append('action', 'addAccountTask');
            formData.append('pk', this.taskSelectedPK);
            formData.append('fk_account', this.taskAddPKAccount);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.showTasks();
                    this.errMsgModTask = false;
                } else {
                    this.errMsgModTask = true;
                }
            }).catch((error) => {
                console.error('Error add account task: ' + error);
                this.disconnect();
            })
        },
        delAccountTask(pkAccount) {
            let formData = new FormData();
            formData.append('action', 'delAccountTask');
            formData.append('pk', this.taskSelectedPK);
            formData.append('fk_account', pkAccount);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.showTasks();
                    this.errMsgModTask = false;
                } else {
                    this.errMsgModTask = true;
                }
            }).catch((error) => {
                console.error('Error del account task: ' + error);
                this.disconnect();
            })
        },
        endTask() {
            let formData = new FormData();
            formData.append('action', 'endTask');
            formData.append('pk', this.taskSelectedPK);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.taskSelectedPK = -1;
                    this.showTasks();
                }
            }).catch((error) => {
                console.error('Error end task: ' + error);
                this.disconnect();
            })
        },
        delTask() {
            let formData = new FormData();
            formData.append('action', 'delTask');
            formData.append('pk', this.taskSelectedPK);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.taskSelectedPK = -1;
                    this.showTasks();
                }
            }).catch((error) => {
                console.error('Error del task: ' + error);
                this.disconnect();
            })
        },
        showUnpaidOrders() {
            let formData = new FormData();
            formData.append('action', 'showUnpaidOrders');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.unpaidOrder = response.data;
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error show unpaid orders: ' + error);
                this.disconnect();
            })
        },
        showUndeliveredOrders() {
            let formData = new FormData();
            formData.append('action', 'showUndeliveredOrders');
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.undeliveredOrder = response.data;
                } else {
                    this.disconnect();
                }
            }).catch((error) => {
                console.error('Error show undelivered order: ' + error);
                this.disconnect();
            })
        },
        modPaidOrder(pk) {
            let formData = new FormData();
            formData.append('action', 'modPaidOrder');
            formData.append('pk', pk);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.showUnpaidOrders();
                    this.showUndeliveredOrders();
                }
            }).catch((error) => {
                console.error('Error mod paid order: ' + error);
                this.disconnect();
            })
        },
        modDeliveredOrder(pk) {
            let formData = new FormData();
            formData.append('action', 'modDeliveredOrder');
            formData.append('pk', pk);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data.ok) {
                    this.showUndeliveredOrders();
                }
            }).catch((error) => {
                console.error('Error mod delivered order: ' + error);
                this.disconnect();
            })
        },
        initializeShoppingCart() {
            this.shoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));
            if (this.shoppingCart !== null) {
                this.shoppingCart.forEach((sc, i) => {
                    this.clothes.forEach(clothe => {
                        if (sc.pk === clothe.pk) {
                            let ready = false;
                            if (clothe.sizes !== null) {
                                clothe.sizes.forEach(size => {
                                    if (sc.size === size.name) {
                                        ready = true;
                                    }
                                })
                            } else {
                                ready = true;
                            }
                            if (ready) {
                                sc.name = clothe.name;
                                sc.price = clothe.price;
                                sc.frontimage = clothe.frontimage;
                                this.shoppingCart[i] = sc;
                            }
                        }
                    })
                })
            } else {
                this.shoppingCart = [];
            }
        },
        addShoppingCart(pk, size) {
            let shoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));
            if (shoppingCart === null)
                shoppingCart = [];
            let found = shoppingCart.map(c => c.pk + c.size).indexOf(pk + size);
            if (found > -1) {
                let free = false;
                if (size !== null) {
                    if (this.clothes.find(c => c.pk === pk).sizes.find(s => s.name === size).stock > shoppingCart[found].nb)
                        free = true;
                } else {
                    if (this.clothes.find(c => c.pk === pk).stock > shoppingCart[found].nb)
                        free = true;
                }
                if (free)
                    shoppingCart[found].nb += 1;
            } else {
                found = this.clothes.filter(c => c.pk === pk).map(c => c.sizes)[0];
                if (found !== null) {
                    found = found.map(s => s.name).indexOf(size);
                    if (found > -1) {
                        shoppingCart.push({"pk": pk, "size": size, "nb": 1});
                        this.scrollTop(10);
                    }
                } else {
                    shoppingCart.push({"pk": pk, "size": size, "nb": 1});
                    this.scrollTop(10);
                }
            }
            localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
            this.initializeShoppingCart();
        },
        delShoppingCart(pk, size, all) {
            let shoppingCart = JSON.parse(localStorage.getItem("shoppingCart"));
            let found = shoppingCart.map(c => c.pk + c.size).indexOf(pk + size);
            if (all) {
                shoppingCart.splice(found, 1);
            } else {
                if (shoppingCart[found].nb < 2) {
                    shoppingCart.splice(found, 1);
                } else {
                    shoppingCart[found].nb -= 1;
                }
            }
            localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
            this.initializeShoppingCart();
        },
        totalShoppingCart() {
            if (this.shoppingCart !== null) {
                let total = 0;
                let clotheReductible = 0;
                this.shoppingCart.forEach(sc => {
                    total += sc.nb * sc.price;
                    if (sc.name.indexOf('T-shirt') > -1 || sc.name.indexOf('Sweat') > -1) {
                        clotheReductible += sc.nb;
                    }
                });
                if (this.posteDelivery) {
                    total += this.posteAmount;
                }
                if (this.codePermanent) {
                    this.codeReduction = this.codeAmount * clotheReductible;
                } else {
                    this.codeReduction = this.codeAmount;
                }
                return total - this.codeReduction;
            }
        },
        verifCouponCode() {
            let formData = new FormData();
            formData.append('action', 'verifCouponCode');
            formData.append('code', this.newOrderCode);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).then((response) => {
                if (response.data !== null) {
                    this.orderCode = this.newOrderCode;
                    this.newOrderCode = '';
                    this.codePermanent = response.data[0].permanent === '1';
                    this.codeAmount = response.data[0].amount;
                    this.errMsgCode = false;
                } else {
                    this.errMsgCode = true;
                }
            }).catch((error) => {
                console.error('Error send coupon code: ' + error);
            })
        },
        addOrder() {
            if (!this.posteDelivery || (this.NPA > 0 && this.city && this.street)) {
                let formData = new FormData();
                formData.append('action', 'addOrder');
                formData.append('products', localStorage.getItem("shoppingCart"));
                formData.append('code', this.orderCode);
                formData.append('poste', this.posteDelivery ? this.posteAmount : 0);
                formData.append('reduction', this.codeReduction);
                formData.append('total', this.totalShoppingCart());
                axios({
                    method: 'post',
                    url: this.url,
                    data: formData,
                    config: this.config
                }).then((response) => {
                    let pk = response.data.pk;
                    if (pk > 0) {
                        this.sendEmailOrder(pk);
                        this.$bvModal.hide('modal-confirm-order');
                        this.showBox = 0;
                        localStorage.removeItem("shoppingCart");
                        this.initializeShoppingCart();
                        this.showHistoryOrders();
                        this.$bvModal.show('modal-order');
                        this.$bvModal.show('modal-thanks-order');
                    } else {
                        this.disconnect();
                    }
                }).catch((error) => {
                    console.error('Error add order: ' + error);
                    this.disconnect();
                })
            } else {
                this.errMsgAccAdd = true;
            }
        },
        sendEmailOrder(pk) {
            let formData = new FormData();
            formData.append('action', 'sendEmailOrder');
            formData.append('pk', pk);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).catch((error) => {
                console.error('Error send email order: ' + error);
            })
        },
        sendEmailOrderLater(pk) {
            let formData = new FormData();
            formData.append('action', 'sendEmailOrderLater');
            formData.append('pk', pk);
            formData.append('email', this.emailForSendEmail);
            axios({
                method: 'post',
                url: this.url,
                data: formData,
                config: this.config
            }).catch((error) => {
                console.error('Error send email order later: ' + error);
            })
        },
        loadImages() {
            this.clotheImages = [];
            this.clotheImages[0] = 'data:image/jpeg;base64,' + this.clotheSelected.frontimage;
            if (this.clotheSelected.backimage !== '')
                this.clotheImages[1] = 'data:image/jpeg;base64,' + this.clotheSelected.backimage;
            // Il va falloir ajouter les images en plus dans les habits
            this.clotheImages.forEach((clotheImage, imageIndex) => {
                let image = new Image();
                image.src = clotheImage;
                image.onload = function () {
                    let divider = (image.height + 200) / 100;
                    if (imageIndex !== 0 && imageIndex !== 1) {
                        image.height = image.height / divider;
                        image.width = image.width / divider;
                    } else {
                        image.height = image.height - 30;
                        image.width = image.width - 30;
                    }
                    document.getElementsByClassName('clotheImage')[imageIndex].style.width = image.width + 'px';
                    document.getElementsByClassName('clotheImage')[imageIndex].style.height = image.height + 'px';
                };
                this.images.push(image);
            });
        },
        scrollTop(showBox) {
            this.disableClickOutside = true;
            this.showBox = showBox;
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        clickOutsideMenu(e) {
            let hide = true;
            e.path.forEach(v => {
                let id = v.id;
                if (id !== undefined) {
                    if ((v.id.includes('menu-')) || (v.id.includes('modal')) || (this.disableClickOutside)) {
                        hide = false;
                    }
                }
            });
            if (hide) {
                this.showBox = 0;
            } else {
                this.disableClickOutside = false;
            }
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'task',
                ghostClass: 'ghost'
            }
        }
    }
}
</script>

<style scoped>
/*Clothes*/
.info {
    display: inline-block;
    width: 100%;
    line-height: 1.5;
}

.info:before {
    content: "";
    border-top: 2px solid #777;
    width: 80%;
    position: absolute;
    left: 10%;
}

.price {
    color: darkgrey;
}

.article {
    width: 250px;
}

.border-article {
    border-top: 1px solid lightgrey;
}

.border-article:last-of-type {
    border-bottom: 1px solid lightgrey;
}

.border-article-inverse {
    border-bottom: 1px solid lightgrey;
}

.border-order {
    border-bottom: 2px solid black;
}

.border-order:last-of-type {
    border-bottom: unset;
}

.trash {
    right: 10px;
}

.w-40px {
    width: 40px;
}

.w-50px {
    width: 50px;
}

.w-92px {
    width: 92px;
}

.poste {
    color: darkgrey;
    transition: all .05s linear;
    -webkit-transition: all .05s linear;
}

.posteSelected {
    color: black;
    font-weight: bold;
}

.posteRadio {
    position: absolute;
    height: 18px;
    width: 18px;
    margin-top: 3px;
    background-color: #eee;
    border-radius: 50%;
}

.posteRadio:after {
    content: "";
    position: absolute;
    display: none;
}

.poste input:checked ~ .posteRadio {
    background-color: black;
}

.poste input:checked ~ .posteRadio:after {
    display: block;
}

.poste .posteRadio:after {
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}

.content-order {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.content-order:first-of-type {
    padding-top: unset;
}

.content-order:last-of-type {
    padding-bottom: unset;
}

.content-info-order {
    flex-wrap: wrap;
}

.size-order {
    margin-left: 0.5rem;
}

.img-order {
    width: 60px;
    height: 60px;
}

.products {
    width: 100%;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
}

.fade-enter, .fade-leave-to, .fade-btn-enter, .fade-btn-leave-to {
    opacity: 0;
}

.img-clothe {
    max-height: 350px;
}

.info {
    font-size: 100%;
}

.bottom-null {
    bottom: 0;
}

.info-product {
    display: contents;
}

.clotheImage:nth-child(2), .clotheImage:nth-child(3) {
    width: 150px !important;
    height: 150px !important;
}

.size:hover {
    background-color: #eeeeee;
}

.size[disabled] {
    color: lightgrey !important;
}

label {
    margin-bottom: unset;
}

.btn-bg-dark {
    background-color: #313131;
}

.btn-bg-dark:hover {
    background-color: #000000;
}

.btn-bg-green {
    background-color: #6fd5a4;
}

@media (min-width: 500px) {
    .clotheImage:nth-child(2), .clotheImage:nth-child(3) {
        width: 200px !important;
        height: 200px !important;
    }
}

@media (min-width: 576px) {
    .products {
        width: 50%;
    }

    .info {
        font-size: 100%;
    }
}

@media (min-width: 992px) {
    .content-text-order {
        display: flex;
        justify-content: space-between;
    }

    .content-info-order {
        flex-wrap: unset;
    }

    .size-order {
        margin-left: auto;
        margin-right: 3rem;
    }

    .price-order {
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .img-order {
        width: 40px;
        height: 40px;
    }

    .clotheImage:nth-child(2), .clotheImage:nth-child(3) {
        width: 300px !important;
        height: 300px !important;
    }

    .products {
        width: 25%;
    }

    .info {
        font-size: 100%;
    }
}

@media (min-width: 1200px) {
    .info-product {
        display: grid;
    }
}

/*Menu*/
#menu-btn {
    right: 1rem;
    margin-top: -1.5rem;
}

#menu-box {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    z-index: 1035;
    background-color: white;
}

.color {
    color: #343a40;
}

/*Login*/
.input:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(94, 83, 18, 0.5);
    /*Copié depuis la class "form-control" de bootstrap*/
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition-property: border-color, box-shadow, -webkit-box-shadow;
    transition-duration: 0.15s, 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s;
}

.input :nth-child(n) {
    outline: none;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    background: transparent;
    color: #d9d5d0;
}

svg path {
    /*Pour résoudre le problème que cause le color juste en haut de :nth-child sur les icons*/
    color: unset !important;
}

::placeholder {
    color: #d9d5d0;
}

.cursor-pointer {
    cursor: pointer;
}

.input,
.input option {
    background: #343a40 !important;
}

.btn-monkeys {
    color: #31363c;
    background-color: #fff;
    border-color: #2b3137;
}

.btn-monkeys:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #30363c;
}

.btn-monkeys:active {
    color: #fff;
    background-color: #30363c;
    border-color: #2a3137;
}

.btn-monkeys:active:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5)
}

.btn-monkeys:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25)
}

/*Task*/
.rounded-bg-top {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group span {
    min-height: 20px;
}

.taskSelected {
    background: #c8ebfb;
}

.circles {
    margin-top: -1.2rem;
}

.circles :nth-child(n) {
    width: 15px;
    height: 15px;
    margin-right: -0.3rem;
}

.bg-red {
    background: red;
}

.bg-green {
    background: green;
}

.bg-darkred {
    background: darkred;
}

.bg-blue {
    background: blue;
}

.textarea-min-height {
    /*Taille d'un Textarea de 3 lignes*/
    min-height: 76px;
}

/*Other*/
.right-null {
    right: 0;
}

.user-select-none {
    user-select: none;
}

:focus {
    outline: 0;
}

/*Stock*/
.case-stock {
    width: 70px;
}

.input-stock::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/*Radio BTN*/
fieldset :first-child:not(.btn-group) {
    display: grid;
}
</style>