<template>
    <div class="text-center">
        <h1 class="font-weight-bold pb-4">La page que vous recherchez n'existe pas</h1>
        <img src="../assets/giphy.gif">
    </div>
</template>

<script>
    export default {
        name: "404"
    }
</script>

<!--<style scoped>-->
<!--</style>-->