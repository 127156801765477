<template>
    <div class="container text-justify text-last-center">
        <h1 class="mt-5">Bienvenue sur notre site internet</h1>
        <h2 class="mt-4">The Mad Monkeys Parkour</h2>
        <h4>
            Nous sommes une association de Parkour à but non-lucratif qui organise des cours de Parkour à Avenches.
            Nous donnons la possibilité à tous d’apprendre, d’évoluer physiquement et de pratiquer le Parkour tout en
            s’amusant. Ces cours permettent de s'entraîner hebdomadairement.
        </h4>

        <h1 class="mt-5">Les cours</h1>
        <div class="d-flex flex-wrap align-items-center">
            <div class="info p-1">
                <div class="rounded-bg shadow m-3 p-3">
                    <h2 class="mt-4">Jeunes</h2>
                    <h4 class="d-inline-block text-last-left">
                        <ul>
                            <li><b>Mercredi de 19h à 20h30</b></li>
                            <li><b>Pour les jeunes</b> à partir de 9 ans révolus et tout ceux qui veulent commencer</li>
                            <li>
                                <b>
                                    <router-link to="/contact">Aux arènes d'Avenches</router-link>
                                </b> en été ☼
                            </li>
                            <li>
                                <b>
                                    <router-link to="/contact">Dans une salle à Avenches</router-link>
                                </b> en hiver (d'octobre à mars)
                            </li>
                        </ul>
                    </h4>
                </div>
            </div>
            <div class="info p-1">
                <div class="rounded-bg shadow m-3 p-3">
                    <h2 class="mt-4">Avancés</h2>
                    <h4 class="d-inline-block text-last-left">
                        <ul>
                            <li><b>Lundi de 19h à 20h30</b></li>
                            <li><b>Pour les traceurs avancés</b></li>
                            <li>
                                <b>
                                    <router-link to="/contact">Aux arènes d'Avenches</router-link>
                                </b> en toute saison
                            </li>
                            <li>Invitation pour les <b>sessions de Parkour</b> (plus d'informations en bas de page)</li>
                        </ul>
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-inline-block rounded-bg shadow m-3 p-3">
            <h2 class="mt-4 text-warning">Les cotisations</h2>
            <h4 class="text-last-left p-3">
                <p>2 cours d'essais possibles</p>
                <b>❏ Semestrielle (1 semestre scolaire) : 200 CHF<br>
                    ❏ Hivernale (durant les heures d’hivers) : 250 CHF<br>
                    ❏ Sans (payé à chaque cours) : 15 CHF</b>
            </h4>
            <img class="img-fluid border border-dark rounded-bg" src="../assets/home/cotisation.png">
        </div>
        <h4>
            Notre expérience de nombreuses années dans la pratique du Parkour, nous permet d'enseigner ce sport aux
            petits et grands. Nous proposons différents tarifs pour satisfaire tout le monde dont celui de deux
            semestress qui correspond à une année scolaire, ce qui fait environ 35 à 40 cours.
        </h4>
        <h4>
            Les informations spécifiques sur les cours et les sessions sont transmises via un groupe <b>Whatsapp</b>.
            Les cours n’ont pas lieu lors des vacances scolaires ainsi que les jours fériés et ponts <b>fribourgeois</b>
            en été, <b>vaudois</b> en hiver.
        </h4>
        <h4>
            Pour commencer les cours,
            <router-link to="/contact">contactez nous</router-link>
            et venez une première fois au rendez-vous avec des habits de sport dans lesquels vous êtes à l'aise. Une
            feuille d'inscription vous sera donc transmise et vous pourrez participer à la leçon.
        </h4>
        <h4 class="mt-5">
            Des cours ont aussi lieu à ...
            <div class="row mt-3">
                <div class="col">
                    Bulle, Romont, la Tour-de-Trême
                </div>
                <div class="col">
                    Valais
                </div>
                <div class="col">
                    Lausanne
                </div>
            </div>
        </h4>
        <div class="mt-4 pb-5">
            <div class="row align-items-center">
                <div class="col">
                    <a target="_blank" href="https://parkoursense.ch">
                        <img class="img-fluid" src="../assets/home/pk_sense.png">
                    </a>
                </div>
                <div class="col">
                    <a target="_blank" href="https://parkourvalais.com/">
                        <img class="img-fluid" src="../assets/home/pk_valais.png">
                    </a>
                </div>
                <div class="col">
                    <a target="_blank" href="https://www.parkourlausanne.ch/">
                        <img class="img-fluid" src="../assets/home/pk_lausanne.png">
                    </a>
                </div>
            </div>
        </div>

        <h1 class="mt-5">Les sessions</h1>
        <div class="info d-inline-block rounded-bg shadow m-3 p-3">
            <h4 class="d-inline-block text-last-left">
                <ul>
                    <li>
                        <b> Le jeudi</b> en été ☼
                        <b>
                            <router-link to="/contact">aux arènes d'Avenches</router-link>
                        </b>
                    </li>
                    <li>
                        <b> Le mercredi</b> en hiver
                        <b>
                            <router-link to="/contact">dans une salle à Avenches</router-link>
                        </b>
                    </li>
                    <li><b>Pour les traceurs avancés</b></li>
                    <li>Nous contacter pour les heures</li>
                </ul>
            </h4>
        </div>
        <h4>
            <b>Les sessions de Parkour</b> ne sont pas des cours et sont destinées <b>aux avancés</b>, elles ont lieu
            <b>par pratiquement tous les temps en été</b>.
        </h4>
        <h4>
            Est-il possible de participer aux sessions, mais pas aux cours ? Oui, mais il faut être un traceur avancé !
            Traceur est le nom qualificatif de celui qui pratique du Parkour.
        </h4>

        <h4 class="mt-5">
            Il est possible de nous trouver avec les localisations et nous contacter sur
            <router-link to="/contact">notre page contact</router-link>
            .
        </h4>
    </div>
</template>

<script>
export default {
    name: "Home"
}
</script>

<style scoped>
h1, h2, h3 {
    font-weight: bold;
    text-align: center;
}

h4 {
    margin-top: 1rem;
    font-weight: normal;
    line-height: unset;
}

.text-last-left {
    text-align-last: left;
}


@media (min-width: 576px) {
    .info {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .info {
        width: 50%;
    }
}
</style>