<template>
    <div class="d-flex flex-wrap justify-content-center pt-5">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/ZUU66WMxphc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <gallery :images="imagesUrl" :index="index" @close="index = null"></gallery>
        <div class="image align-self-center" v-for="(imageUrl, imageIndex) in imagesUrl" :key="imageIndex" @click="index = imageIndex"
             :style="{backgroundImage: 'url(' + imageUrl + ')'}"
        ></div>
    </div>
</template>

<script>
    import VueGallery from 'vue-gallery';

    export default {
        name: "Medias",
        data() {
            return {
                imagesUrl: [
                    require('@/assets/medias/media_1.jpg'),
                    require('@/assets/medias/media_2.jpg'),
                    require('@/assets/medias/media_3.jpg'),
                    require('@/assets/medias/media_4.jpg'),
                    require('@/assets/medias/media_5.jpg'),
                    require('@/assets/medias/media_6.jpg'),
                    require('@/assets/medias/media_7.jpg'),
                    require('@/assets/medias/media_8.jpg'),
                    require('@/assets/medias/media_9.jpg'),
                    require('@/assets/medias/media_10.jpg'),
                    require('@/assets/medias/media_11.jpg'),
                    require('@/assets/medias/media_12.jpg'),
                    require('@/assets/medias/media_13.jpg'),
                    require('@/assets/medias/media_14.jpg'),
                    require('@/assets/medias/media_15.jpg'),
                    require('@/assets/medias/media_16.jpg'),
                    require('@/assets/medias/media_17.jpg'),
                    require('@/assets/medias/media_18.jpg'),
                    require('@/assets/medias/media_19.jpg'),
                    require('@/assets/medias/media_20.jpg'),
                ],
                images: [],
                index: -1
            };
        },
        components: {
            'gallery': VueGallery
        },
        created() {
            this.loadImages();
        },
        methods: {
            loadImages() {
                this.imagesUrl.forEach((imageUrl, imageIndex) => {
                    let image = new Image();
                    image.src = imageUrl;
                    image.onload = function () {
                        let divider = Math.round(image.height + 200) / 300;
                        image.height = image.height / divider;
                        image.width = image.width / divider;
                        document.getElementsByClassName('image')[imageIndex].style.width = image.width + 'px';
                        document.getElementsByClassName('image')[imageIndex].style.height = image.height + 'px';
                    };
                    this.images.push(image);
                });
            }
        }
    }
</script>

<style scoped>
    .image {
        float: left;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border: 1px solid #ebebeb;
        margin: 5px;
    }
</style>