<template>
    <div id="app" class="position-relative min-vh-100">
        <navbar/>
        <router-view class="container-fluid"/>
        <foot/>
    </div>
</template>

<script>
    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'
    import navbar from './components/NavBar.vue'
    import foot from './components/Footer.vue'

    export default {
        name: "App",
        components: {
            navbar,
            foot
        }
    }
</script>

<style>
    .container-fluid {
        /* Pour que le bas de la page ne cache pas le contenu */
        padding-bottom: 6rem;
    }

    .text-last-center {
        text-align-last: center;
    }

    .modal-title {
        /*Pour que le titre des popup modal soit centrées*/
        margin: 0 -2rem 0 auto;
    }

    .rounded-bg {
        border-radius: 0.8rem !important;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>