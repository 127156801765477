<template>
    <div class="container mt-5 text-justify text-last-center">
        <h2 class="mt-5">Qu'est ce que le Parkour ?</h2>
        <h3 class="mt-4">Le principe du Parkour</h3>
        <div class="compartment row-reverse">
            <img class="img-w-50 p-4 img-fluid" src="../assets/home/parkour_1.jpg">
            <h4>
                Le principe de ce sport est de passer des obstacles de la manière la plus fluide, efficace et le plus
                rapide possible. Ce sport se pratique donc notamment à l'extérieur en milieu urbain, les obstacles étant
                ce qu'offre la ville : murs, barrières, statues, etc. Courir, sauter, grimper, ramper, faire des
                roulades sont des mouvements de base que l'être humain est capable d'exécuter. Le Parkour utilise ces
                mouvements et en développe la maîtrise afin que son pratiquant apprenne à connaître son corps et ses
                limites. Cela va permettre de se sentir en sécurité et de pouvoir bouger aisément dans des situations
                parfois périlleuses. Le but est également de se fixer des limites et des objectifs réalistes en fonction
                de son état physique et de la configuration de l'environnement. Privilégier le bien-être, la
                satisfaction du mouvement accompli et non la compétition, c'est le chemin philosophique que le Parkour
                offre à ses pratiquants.
            </h4>
        </div>
        <h3 class="mt-5">Les sports associés au Parkour</h3>
        <h4>Il existe également des dérivés de ce sport et cela vaut la peine de les décrire, car ils sont souvent
            réunis sous le nom du Parkour.</h4>
        <div class="compartment">
            <img class="img-w-50 p-4 img-fluid" src="../assets/home/parkour_2.jpg">
            <h4>
                Le freerun ou freeruning a pour but de développer des figures artistiques de tous types en utilisant le
                mobilier urbain encore une fois. Cette pratique est plus axée sur la compétition. Il ne s'agit plus
                forcément d'être rapide, mais d'impressionner par des figures comme par exemple : sauter la tête à
                l'envers, faire des figures sur des barrières, etc. C'est le visage du freestyle (côté d'un sport qui
                met en avant le show artistique plutôt que la vitesse) du Parkour. Plusieurs vidéos sur internet mixent
                ces deux pratiques.
            </h4>
        </div>
        <div class="compartment row-reverse">
            <img class="img-w-50 p-4 img-fluid" src="../assets/home/parkour_3.jpg">
            <h4>
                Le tricking consiste à réaliser des figures uniquement au sol, c'est une sorte de freerun sans mobilier
                urbain. Il est fréquent de trouver des artistes pratiquant un sport en y ajoutant du tricking. Comme par
                exemple le maniement du sabre qui permet de réaliser des shows artistiques en public sous forme de
                combats acrobatiques. Ou encore le foot freestyle, qui consiste à jongler et à réaliser des figures
                avec un ballon à ses pieds.
            </h4>
        </div>
        <h3 class="mt-5">L'histoire du Parkour</h3>
        <div class="compartment">
            <img class="img-w-50 p-4 img-fluid" src="../assets/home/parkour_4.jpg">
            <h4>
                Le Parkour est né dans les années 1990 à Paris. Les avis diffèrent sur l'identité du fondateur de ce
                sport, mais les noms de David Belle et du groupe des Yamakasis ressortent le plus. Le père de David, qui
                était pompier et avait fait la guerre, a enseigné à son fils la plupart des techniques qu'il utilise
                aujourd'hui. David aurait appris ce sport afin de s'enfuir de bagarre de rue fréquente dans son
                quartier.
            </h4>
        </div>
        <div class="compartment row-reverse">
            <img class="img-w-50 p-4 img-fluid" src="../assets/home/parkour_5.jpg">
            <h4>
                En 1998, David Belle quitte le groupe des Yamakasis, avec qui il a développé les différentes techniques
                de franchissement d'obstacles et refonde un groupe appelé "Les Traceurs". Ce nouveau groupe fait
                connaître le Parkour pratiqué par David Belle et continue de se développer. Les septs autres membres
                fondateurs restés dans le groupe des Yamakasis propagent le Parkour en France en 2001, grâce au film
                Yamakasi de Luc Besson. Au fil des années, ce sport devient de plus en plus connu et relayé par les
                médias, particulièrement grâce à des vidéos sur Youtube. Depuis, des écoles de Parkour ont été ouvertes,
                des vidéos ont été postées, plusieurs documentaires et films sont sortis sur ce sport de rue.
            </h4>
        </div>
        <div class="compartment">
            <img class="img-w-25 p-4 img-fluid" src="../assets/home/parkour_6.jpg">
            <h4>
                De nos jours, le Parkour est connu mondialement et des compétitions ont été créées. Nous pouvons dire
                que le pari des Yamakasis et de David Belle qui était de faire connaître le Parkour a été tenu. Ils
                faisaient déjà quelques figures qui s'apparentaient à du freerun, mais l'histoire du freerun s'est
                réellement développée quand Sébastien Foucan s'est également séparé des Yamakasis afin de créer sa
                propre communauté. Il parti au Royaume-Uni et y démocratisa le Parkour, qui prit une tournure plus
                acrobatique, le freerunning est donc né ! Plus tard en France, le freerun se fera connaître et se
                développera notamment grâce à Simon Nogueira, Yoann Leroux et leur fédération, la French Freerun Family.
            </h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Histoire"
    }
</script>

<style scoped>
    h1, h2, h3 {
        font-weight: bold;
        text-align: center;
    }

    h4 {
        margin-top: 1rem;
        font-weight: normal;
        line-height: unset;
    }

    @media (min-width: 1200px) {
        .compartment {
            display: flex;
            align-items: center;
        }

        .row-reverse {
            flex-direction: row-reverse;
        }

        .img-w-50 {
            width: 50%;
        }

        .img-w-25 {
            width: 25%;
        }
    }
</style>