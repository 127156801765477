<template>
    <footer class="position-absolute fixed-bottom text-center bg-dark p-3">
        <span>Copyright © {{new Date().getFullYear()}} The Mad Monkeys</span>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
    footer {
        color: rgba(255, 255, 255, 0.75);
    }
</style>