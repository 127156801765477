<template>
    <div class="text-center">
        <div class="row">
            <div class="col">
                <a target="_blank" href="https://www.readytobrand.ch/">
                    <img class="img-fluid" src="../assets/sponsors/r2b.png">
                </a>
            </div>
            <div class="col">
                <a target="_blank" href="https://www.commune-avenches.ch/">
                    <img class="img-fluid" src="../assets/sponsors/commune-avenches.png">
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <a target="_blank" href="https://www.vaudoise.ch/fr">
                    <img class="img-fluid" src="../assets/sponsors/vaudoise.png">
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <a target="_blank" href="https://www.axa.ch/">
                    <img class="img-fluid" src="../assets/sponsors/axawin.jpg"/>
                </a>
            </div>
            <div class="col">
                <a target="_blank" href="https://www.trouver-un-cours.ch/sport-mouvement/"
                   title="Cours de Sport et Mouvement">
                    <img class="img-fluid" src="../assets/sponsors/trouver-un-cours.png"/>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <a target="_blank" href="https://samaritains-estavayer.ch/">
                    <img class="img-fluid" src="../assets/sponsors/samaritains_esta.jpg">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sponsors"
}
</script>

<style scoped>
img {
    max-height: 200px;
    min-width: 200px;
}

.col {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

@media (min-width: 768px) {
    .col {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
}
</style>