<template>
    <div class="text-center">
        <div class="row">
            <div class="col">
                <a href="tel:+41 78 956 36 42" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon icon="phone-alt" transform="shrink-6" class="text-white"/>
                    </font-awesome-layers>
                </a>
                <h5>Téléphone</h5>
                <a href="tel:+41 78 956 36 42">+41 78 956 36 42</a>
            </div>
            <div class="col">
                <a href="mailto:parkour.themadmonkeys@gmail.com" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon icon="envelope" transform="shrink-6" class="text-white"/>
                    </font-awesome-layers>
                </a>
                <h5>Adresse email</h5>
                <a href="mailto:parkour.themadmonkeys@gmail.com">parkour.themadmonkeys@gmail.com</a>
            </div>
            <div class="col">
                <a target="_blank" href="https://goo.gl/maps/AKVLYyAGmUCD6ZXr9" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon icon="map-marker-alt" transform="shrink-6" class="text-white"/>
                        <font-awesome-icon icon="sun" transform="shrink-11 up-4 right-3" class="text-warning"/>
                    </font-awesome-layers>
                </a>
                <h5>Lieu des cours en été</h5>
                <a target="_blank" href="https://goo.gl/maps/AKVLYyAGmUCD6ZXr9">Arènes d'Avenches</a>
            </div>
            <div class="col">
                <a target="_blank" href="https://goo.gl/maps/8sKGM2AdEHrPzKkL8" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon icon="map-marker-alt" transform="shrink-6" class="text-white"/>
                        <font-awesome-icon icon="snowflake" transform="shrink-11 up-4 right-3" class="text-primary"/>
                    </font-awesome-layers>
                </a>
                <h5>Lieu des cours en hiver</h5>
                <a target="_blank" href="https://goo.gl/maps/8sKGM2AdEHrPzKkL8">Salle de gym du collège d'Avenches</a>
            </div>
            <!--            <div class="col">-->
            <!--                <a target="_blank" href="https://www.facebook.com/TheMadMonkeys.PK" class="display-1">-->
            <!--                    <font-awesome-layers class="fa-3x">-->
            <!--                        <font-awesome-icon icon="circle" class="color"/>-->
            <!--                        <font-awesome-icon :icon="['fab', 'facebook-f']" transform="shrink-6" class="text-white"/>-->
            <!--                    </font-awesome-layers>-->
            <!--                </a>-->
            <!--                <h5>Facebook</h5>-->
            <!--                <a target="_blank" href="https://www.facebook.com/TheMadMonkeys.PK">TheMadMonkeys Parkour</a>-->
            <!--            </div>-->
            <div class="col">
                <a target="_blank" href="https://www.youtube.com/channel/UCWTnAWweTnuddv9xQZhdANA" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon :icon="['fab', 'youtube']" transform="shrink-6" class="text-white"/>
                    </font-awesome-layers>
                </a>
                <h5>Youtube</h5>
                <a target="_blank" href="https://www.youtube.com/channel/UCWTnAWweTnuddv9xQZhdANA"> The Mad Monkeys</a>
            </div>
            <div class="col">
                <a target="_blank" href="https://www.instagram.com/themadmonkeys.pk" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon :icon="['fab', 'instagram']" transform="shrink-6" class="text-white"/>
                    </font-awesome-layers>
                </a>
                <h5>Instagram</h5>
                <a target="_blank" href="https://www.instagram.com/themadmonkeys.pk">TheMadMonkeys.PK</a>
            </div>
            <div class="col">
                <a target="_blank" href="https://twitter.com/TheMadMonkeys_" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon :icon="['fab', 'twitter']" transform="shrink-6" class="text-white"/>
                    </font-awesome-layers>
                </a>
                <h5>Twitter</h5>
                <a target="_blank" href="https://twitter.com/TheMadMonkeys_">@TheMadMonkeys_</a>
            </div>
            <div class="col">
                <a target="_blank" href="https://www.tiktok.com/@themadmonkeys.pk" class="display-1">
                    <font-awesome-layers class="fa-3x">
                        <font-awesome-icon icon="circle" class="color"/>
                        <font-awesome-icon :icon="['fab', 'tiktok']" transform="shrink-6" class="text-white"/>
                    </font-awesome-layers>
                </a>
                <h5>TikTok</h5>
                <a target="_blank" href="https://www.tiktok.com/@themadmonkeys.pk">@TheMadMonkeys.PK</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Contact"
}
</script>

<style scoped>
.color {
    color: #343a40;
}

.col {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

/*For resolve the problem of the youtube icon*/
.fa-w-18 {
    width: 1em;
}

@media (min-width: 768px) {
    .col {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
}

@media (min-width: 1921px) {
    .col {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .display-1 {
        font-size: 7rem;
    }
}
</style>